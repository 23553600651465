import React, { useCallback, useEffect, useState } from "react";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Search from "./Search";
import BookingSidebar from "./BookingSidebar";
import ButtonLoader from "../Common/ButtonLoader";

function LeftSide({
  setActiveView,
  selectedCategory,
  setSelectedCategory,
  selectedBooking,
  setSelectedBooking,
  handleBookingClick,
  bookingData,
  setBookingData,
}) {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const token = useSelector((state) => state.userData.user.accessToken);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchBookings = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    const token = await checkAuthAndRedirect(
      navigate,
      dispatch,
      loggedUserData
    );

    const isValidSearchQuery = searchQuery && searchQuery.length >= 3;
    const sanitizedQuery = isValidSearchQuery
      ? encodeURIComponent(searchQuery.replace(/[^a-zA-Z0-9 ]/g, ""))
      : "";

    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/bookings?Page=${page}&PageSize=10${
      sanitizedQuery ? `&Search=${sanitizedQuery}` : ""
    }`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok) {
        setBookingData((prevStaffs) =>
          page === 1 ? data.list : [...prevStaffs, ...data.list]
        );
        setHasMore(data.list.length > 0);
      } else {
        toast.error(data.detail || "Internal Server Error");
        setHasMore(false);
      }
    } catch (error) {
      toast.error("Internal Server Error");
      setHasMore(false);
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [loading, hasMore, page, searchQuery, token]);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line
  }, [searchQuery, page, token]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 100 && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1); // Reset to first page on a new search
    setHasMore(true);
  };
  return (
    <div
      onScroll={handleScroll}
      className="w-full lg:w-1/3 md:border-r-[1px]  border-[#ddecf3]"
    >
      <Search onSearchChange={handleSearchChange} searchTerm={searchQuery} />
      {loading && page === 1 ? (
        <div className="flex justify-center items-center h-full">
          <ButtonLoader />
        </div>
      ) : (
        <BookingSidebar
        selectedCategory={selectedCategory}
        bookingData={bookingData}
        handleBookingClick={handleBookingClick}
        setSelectedBooking={setSelectedBooking}
        />
      )}

      {loading && page > 1 && (
        <div className="w-full flex justify-center">
          <ButtonLoader />
        </div>
      )}
    </div>
  );
}

export default LeftSide;
