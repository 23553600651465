import React from "react";
import Layout from "../Components/Common/Layout";
import BookingList from "../Components/Booking/BookingList";

function Booking() {
  return (
    <Layout>
      <BookingList />
    </Layout>
  );
}

export default Booking;
