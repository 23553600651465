import { Plus } from "lucide-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../Common/ButtonLoader";
import { useNavigate } from "react-router-dom";

export default function TopActions({
  setActiveView,
  selectedCategory,
  setSelectedCategory,
  selectednooking,
  setSelectedBooking,
}) {
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center">
        <div className="font-bold text-xl mb-3">Bookings</div>
        <button className="font-medium mb-3 bg-[#ddecf3] hover:bg-primary hover:text-white text-primary flex items-center px-3 py-3 md:px-5 rounded-lg">
          {loading ? (
            <ButtonLoader />
          ) : (
            <>
              <Plus size={22} className="me-3" /> New Booking
            </>
          )}
        </button>
      </div>
      <div className="flex md:space-x-4 space-x-3 mt-3 md:mt-0">
        {["Upcoming", "Past", "Unconfirmed", "Cancelled", "New"].map(
          (category) => (
            <button
              key={category}
              onClick={() => {
                setSelectedCategory(category);
                setSelectedBooking(null);
              }}
              className={`pb-2 md:text-sm text-xs ${
                selectedCategory === category
                  ? "text-primary border-b-2 font-bold border-primary"
                  : "text-black text-opacity-60"
              }`}
            >
              {category}
            </button>
          )
        )}
      </div>
    </div>
  );
}
