import React from "react";

function BookingSidebar({ selectedCategory, bookingData, setSelectedBooking }) {
  return (
    <div className="space-y-6">
      {bookingData?.length > 0 ? (
        bookingData.map((booking) => {
          const startsOnDate = new Date(booking.startsOn).toLocaleDateString();
          const startsOnTime = new Date(booking.startsOn).toLocaleTimeString(
            [],
            { hour: "2-digit", minute: "2-digit" }
          );
          const priceFormatted = `$${booking.finalPrice.toFixed(2)}`;

          return (
            <div
              className="text-sm rounded-tl-lg overflow-hidden"
              key={booking.id}
            >
              <div className="py-3 md:pb-4 flex items-center text-primary bg-[#F0F5F7] border-b border-[#ddecf3] font-medium">
                <div className="px-4">{startsOnDate}</div>
              </div>
              <div
                className="w-full px-4 py-4 md:py-6 border-b border-[#ddecf3] cursor-pointer"
                onClick={() => setSelectedBooking(booking.id)}
              >
                <div className="flex">
                  <div className="pe-4 border-r-[1px] border-[#ddecf3] text-sm text-black text-opacity-50">
                    {startsOnTime}
                    <br />
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <div className="ps-4">
                      <h2 className="font-regular">{booking.customerName}</h2>
                      <h2 className="text-sm text-black font-medium">
                        {booking.serviceName}
                      </h2>
                    </div>
                    <div className="text-right">
                      <h2
                        className={`text-sm font-medium ${
                          booking.status === "Confirmed"
                            ? "text-[#55BAAE]"
                            : booking.status === "Unconfirmed"
                            ? "text-[#E6B400]"
                            : "text-[#E6B400]"
                        }`}
                      >
                        {booking.status}
                      </h2>
                      <h2 className="text-sm text-black text-opacity-60 font-semibold">
                        {priceFormatted}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="w-full text-center py-10">
          <h2 className="text-sm text-gray-500">
            No data found for {selectedCategory} bookings.
          </h2>
        </div>
      )}
    </div>
  );
}

export default BookingSidebar;
