import React, { useState } from "react";
import { Plus } from "lucide-react";
import TopActions from "./TopAction";
import LeftSide from "./LeftSide";
import BookingDetails from "./BookingDetails";

function BookingList() {
  const [selectedCategory, setSelectedCategory] = useState("Upcoming");
  const [bookingData, setBookingData] = useState([]);
  const [activeView, setActiveView] = useState("booking");
  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
    setActiveView("booking");
  };

  return (
    <>
      <div className="w-full max-w-6xl mx-auto">
        <TopActions
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedBooking={selectedBooking}
          setSelectedBooking={setSelectedBooking}
        />

        <div className="rounded-lg bg-white border-[1px] border-[#ddecf3] shadow-lg">
          <div className="flex flex-col lg:flex-row">
            <LeftSide
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedBooking={selectedBooking}
              setSelectedBooking={setSelectedBooking}
              handleBookingClick={handleBookingClick}
              bookingData={bookingData}
              setBookingData={setBookingData}
            />
            <div className="min-h-screen w-full lg:w-2/3 bg-white rounded-xl md:rounded-r-xl">
              {selectedBooking ? (
                <BookingDetails
                  activeView={activeView}
                  setActiveView={setActiveView}
                  selectedBooking={selectedBooking}
                />
              ) : (
                <div className="md:px-0 px-4 min-h-screen w-full lg:w-2/3-50  rounded-r-xl flex justify-center items-center">
                  <div className="flex flex-col items-center text-center mb-6">
                    <img
                      width={50}
                      src="/assets/images/logodark.png"
                      alt="logo"
                    />
                    <h2 className="md:text-2xl text-xl font-semibold  text-primary my-3">
                      Bookings
                    </h2>
                    <p className="text-sm text-gray-600 md:w-2/3">
                      On the left sidebar you can see the list of confirmed and
                      unconfirmed bookings and on the right more in-depth
                      information about specific booking.
                    </p>
                    <button className="font-medium mt-7 bg-[#ddecf3] hover:bg-primary hover:text-white text-primary flex items-center px-6 py-3 rounded-lg">
                      <Plus size={22} className="me-3" /> New Booking
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingList;
